import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  constructor(private router: Router) { }
  isMenuOpen = false;
  ngOnInit() {
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  homeFabricsClicked(){
    this.router.navigate(['/home-division']);
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  //sustainable-fabrics
  sustainableFabricsClicked(){
    this.router.navigate(['/sustainable-fabrics']);
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }

}
