import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private router: Router) { }
  isMenuOpen = false;
  //isImageLoaded=false;
  ngOnInit() {
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
   /*sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }*/
  /*async  isLoad(){
    
    await this.sleep(2000);
    this.isImageLoaded=true;
    
  }*/
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }
}
