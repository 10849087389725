import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeFabricsDivisionComponent } from './home-fabrics-division/home-fabrics-division.component';
import { HomeFabricsComponent } from './home-fabrics/home-fabrics.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { ProductsComponent } from './products/products.component';
import { SustainableFabricsComponent } from './sustainable-fabrics/sustainable-fabrics.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { UpholstryComponent } from './upholstry/upholstry.component';

const routes: Routes = [{path:'home',component:HomeComponent,data: {animation: '1'}},{path:'products',component:ProductsComponent,data: {animation: '2'}},{
  path:'about-us',component:AboutUsComponent,data: {animation: '3'}
},{path:'contact-us',component:ContactUsComponent,data: {animation: '4'}},{path:'our-team',component:OurTeamComponent,data: {animation: '5'}},{path:'sustainable-fabrics',component:SustainableFabricsComponent,data: {animation: '6'}},{path:'home-fabrics',component:HomeFabricsComponent,data: {animation: '7'}},{path:'login',component:LoginComponent,data: {animation: '8'}},{path:'track',component:TrackOrderComponent,data: {animation: '8'}},{path:'home-division',component:HomeFabricsDivisionComponent,data: {animation: '9'}},{path:'upholstry',component:UpholstryComponent,data: {animation: '10'}},{path:'',component:HomeComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
