import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private httpClient:HttpClient,private snackBar: MatSnackBar,private router:Router) { }
  email;
  password;
  name;
  emailToShow;
  balance;
  dateOfDelivery;
  isMenuOpen = false;
  ngOnInit() {
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  loginClicked(){
    if(this.email.length==0 || this.email.replace(/\s/g, "").length==0)
    {
      this.snackBar.open('Please enter your email', 'OK', { duration: 0 });
    }else if(this.password.length==0 || this.password.replace(/\s/g, "").length==0)
    {
      this.snackBar.open('Please enter the password', 'OK', { duration: 0 });
    }else{
      this.httpClient.get('assets/json/users.json').subscribe((data:any) => {
        console.warn(data);
        for(let i=0;i<data.length;i++)
        {
          if(data[i]['Email']==this.email)
          {
            if(data[i]['Password']==this.password)
            {
              this.router.navigate(['/track',{email:this.email}]);
              
            }else{
              this.snackBar.open('Incorrect Password', 'OK', { duration: 0 });
              break;
            }
          }
        }
        
      })
    }
    
    
  }

}
