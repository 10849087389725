import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }
  isMenuOpen = false;
 
  ngOnInit() {
    
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }
}
