import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upholstry',
  templateUrl: './upholstry.component.html',
  styleUrls: ['./upholstry.component.css']
})
export class UpholstryComponent implements OnInit {

  constructor(private router:Router) { }
  productCategories=[{
    "title":"Akron",
    "description":"You’ll never find elegance and a fine fabric go out of style",
    "images":[
      "assets/images/home-fabrics/akron/1-Akron.jpg",
      "assets/images/home-fabrics/akron/2-Akron.jpg",
      "assets/images/home-fabrics/akron/3-Akron.jpg",
      "assets/images/home-fabrics/akron/4-Akron.jpg"
    ]
  },
  {
    "title":"Azore",
    "description":"Quality textiles are not just about how they look or feel, but also about the story woven into their production",
    "images":[
      "assets/images/home-fabrics/Azore/01-Azore.jpg",
      "assets/images/home-fabrics/Azore/02-Azore.jpg",
      "assets/images/home-fabrics/Azore/03-Azore.jpg",
      "assets/images/home-fabrics/Azore/04-Azore.jpg"
    ]
  },
  {
    "title":"Berkeley",
    "description":"If your style reflects your fabrics then your fabrics must reflect your style",
    "images":[
      "assets/images/home-fabrics/Berkeley/01 Berkeley.jpg",
      "assets/images/home-fabrics/Berkeley/02 Berkeley.jpg",
      "assets/images/home-fabrics/Berkeley/03 Berkeley.jpg",
      "assets/images/home-fabrics/Berkeley/04 Berkeley.jpg"
    ]
  },
  ];
  allProductCategories=[
    {
      "title":"Akron",
      "description":"You’ll never find elegance and a fine fabric go out of style",
      "images":[
        "assets/images/home-fabrics/akron/1-Akron.jpg",
        "assets/images/home-fabrics/akron/2-Akron.jpg",
        "assets/images/home-fabrics/akron/3-Akron.jpg",
        "assets/images/home-fabrics/akron/4-Akron.jpg"
      ]
    },
    {
      "title":"Azore",
      "description":"Quality textiles are not just about how they look or feel, but also about the story woven into their production",
      "images":[
        "assets/images/home-fabrics/Azore/01-Azore.jpg",
        "assets/images/home-fabrics/Azore/02-Azore.jpg",
        "assets/images/home-fabrics/Azore/03-Azore.jpg",
        "assets/images/home-fabrics/Azore/04-Azore.jpg"
      ]
    },
    {
      "title":"Berkeley",
      "description":"If your style reflects your fabrics then your fabrics must reflect your style",
      "images":[
        "assets/images/home-fabrics/Berkeley/01 Berkeley.jpg",
        "assets/images/home-fabrics/Berkeley/02 Berkeley.jpg",
        "assets/images/home-fabrics/Berkeley/03 Berkeley.jpg",
        "assets/images/home-fabrics/Berkeley/04 Berkeley.jpg"
      ]
    },
    
    
    {
      "title":"Cedar",
      "description":"Be it passing fashion or a lasting style, find a fabric that does both",
      "images":[
        "assets/images/home-fabrics/Cedar/01 Cedar.jpg",
        "assets/images/home-fabrics/Cedar/02 Cedar.jpg",
        "assets/images/home-fabrics/Cedar/03 Cedar.jpg",
        "assets/images/home-fabrics/Cedar/04 Cedar.jpg"
      ]
    },
    
    {
      "title":"Darwin",
      "description":"Choosing the wrong fabric design can leave a lasting impression, like a stain that never fades",
      "images":[
        "assets/images/home-fabrics/Darwin/01 Darwin.jpg",
        "assets/images/home-fabrics/Darwin/02 Darwin.jpg",
        "assets/images/home-fabrics/Darwin/03 Darwin.jpg",
        "assets/images/home-fabrics/Darwin/04 Darwin.jpg"
      ]
    },
    
    {
      "title":"Elon",
      "description":"Richly textured, plush as a cloud, royal and regal.",
      "images":[
        "assets/images/home-fabrics/Elon/01 Elon.jpg",
        "assets/images/home-fabrics/Elon/02 Elon.jpg"
        
      ]
    },
    {
      "title":"Emory",
      "description":"The way this material catches the light is mesmerizing, like a subtle glow that radiates beauty and style.",
      "images":[
        "assets/images/home-fabrics/Emory/01 Emory.jpg",
        "assets/images/home-fabrics/Emory/02 Emory.jpg",
        "assets/images/home-fabrics/Emory/03 Emory.jpg",
        "assets/images/home-fabrics/Emory/04 Emory.jpg"
      ]
    },
    
    
    
    {
      "title":"Ikaria",
      "description":"Like a warm embrace, these fabrics enfold the body in a soft, comforting cocoon of luxury and style.",
      "images":[
        "assets/images/home-fabrics/Ikaria/01 Ikaria.jpg",
        "assets/images/home-fabrics/Ikaria/02 Ikaria.jpg",
        "assets/images/home-fabrics/Ikaria/03 ikaria.jpg",
        "assets/images/home-fabrics/Ikaria/04 Ikaria.jpg"
      ]
    },
    
    {
      "title":"New Berry",
      "description":"When it comes to fashion, nothing speaks of grace and sophistication quite like the texture and drape of fine materials.",
      "images":[
        "assets/images/home-fabrics/New berry/01 New berry.jpg",
        "assets/images/home-fabrics/New berry/02 New Berry.jpg",
        "assets/images/home-fabrics/New berry/03 New Berry.jpg",
        "assets/images/home-fabrics/New berry/04 New berry.jpg"
      ]
    },
    
    
  ] 
  isMenuOpen = false;
  ngOnInit() {
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  paginationPage(event)
  {
    
    let elementsToShow=(event['pageIndex']+1)*3;
    elementsToShow-=1;
    this.productCategories=[];
    this.productCategories.push(this.allProductCategories[elementsToShow-2]);
    this.productCategories.push(this.allProductCategories[elementsToShow-1]);
    this.productCategories.push(this.allProductCategories[elementsToShow]);
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }

}
