import { Component, OnInit, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Component({
  selector: 'app-outside-header',
  templateUrl: './outside-header.component.html',
  styleUrls: ['./outside-header.component.css']
})
export class OutsideHeaderComponent implements OnInit {

  constructor() { }
  isFixedNavbar;
  @HostBinding('class.navbar-opened') navbarOpened = false;
  
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(offset > 10) {
      this.isFixedNavbar = true;
    } else {
      this.isFixedNavbar = false;
    }
  }

  toggleNavbar() {
    this.navbarOpened = !this.navbarOpened;
  }
  hideLogo=true;
  //navItems=['Home','Products','About Us','Contact Us','Our Team'];
  navItems=['Home','Products','About Us','Contact Us'];
  color='#FFFFFF';
  borderBottom=['5px solid #E0CDA2','','','','']
  @Output() navData = new EventEmitter<any>(); isMenuOpen = false;
  ngOnInit() {
    for(let j=0;j<this.borderBottom.length;j++)
    {
      this.borderBottom[j]='';
    }
    if(window.location.pathname=='/home')
    {
      this.color='#FFFFFF';
      this.hideLogo=true;
      this.borderBottom[0]='5px solid #E0CDA2'
    }
    else if(window.location.pathname=='/products')
    {
      this.color='#FFFFFF';
      this.hideLogo=false;
      this.borderBottom[1]='5px solid #E0CDA2'
    }
    else if(window.location.pathname=='/about-us')
    {
      this.color='#FFFFFF';
      this.hideLogo=false;
      this.borderBottom[2]='5px solid #E0CDA2'
    }
    else if(window.location.pathname=='/our-team')
    {
      this.color='#000000';
      this.hideLogo=false;
      this.borderBottom[4]='5px solid #E0CDA2'
    }
    else if(window.location.pathname=='/contact-us')
    {
      this.color='#FFFFFF';
      this.hideLogo=false;
      this.borderBottom[3]='5px solid #E0CDA2'
    }else{
      this.color='#FFFFFF';
      this.hideLogo=true;
      this.borderBottom[0]='5px solid #E0CDA2'
    }
  }
  
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  menuItemClicked(event,i){
    for(let j=0;j<this.borderBottom.length;j++)
    {
      this.borderBottom[j]='';
    }
    this.borderBottom[i]='5px solid #E0CDA2'
    if(event=='About Us')
    {
      this.color='#FFFFFF';
      this.hideLogo=false;
    }if(event=='Products'){
      this.color='#FFFFFF';
      this.hideLogo=false;
    }if(event=='Home')
    {
      this.color='#FFFFFF';
      this.hideLogo=true;
    }
    if(event=='Contact Us')
    {
      this.color='#FFFFFF';
      this.hideLogo=false;
    }
    if(event=='Our Team')
    {
      this.color='#000000';
      this.hideLogo=false;
    }
    this.navData.emit(event);

  }

}
