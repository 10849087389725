import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-message',
  templateUrl: './our-message.component.html',
  styleUrls: ['./our-message.component.css']
})
export class OurMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
