import { Component,HostListener, HostBinding } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { fader } from './route-animation';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ // <-- add your animations here
     fader
    ]
})
export class AppComponent {
  title = 'mascella';
  hideHeader=true;
  urlImage='linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("assets/images/home/cover-image.jpg")';
  aboutUsPage=false;
  isHomePage=true;
  showFooter=false;
  constructor(private router: Router){
    
  }
  ngOnInit(){    
    
  }
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'] ;
  }
  
  getNavData(event){
    console.warn(event);
    if(event=='Products')
    {
      this.isHomePage=false;
      this.aboutUsPage=false;
      this.showFooter=true;
      this.router.navigate(['/products']);
      this.urlImage='rgba(255, 255, 255)';
      
    }
    else if(event=='About Us')
    {
      this.showFooter=true;
      this.isHomePage=false;
      this.aboutUsPage=true;
      this.router.navigate(['/about-us']);
      //this.urlImage='rgba(255, 255, 255) '
      this.urlImage='linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("assets/images/products/products-cover.jpg")';
    }else if(event=='Home')
    {
      this.showFooter=false;
      this.router.navigate(['/home']);
      this.isHomePage=true;
      this.aboutUsPage=false;
      this.urlImage='linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("assets/images/home/cover-image.jpg")';
    }else if(event=='Our Team')
    {
      this.showFooter=true;
      this.isHomePage=false;
      this.aboutUsPage=false;
      this.router.navigate(['/our-team']);
      this.urlImage='rgba(255, 255, 255) '
    }
    else{
      this.showFooter=false;
      this.isHomePage=false;
      this.aboutUsPage=false;
      this.router.navigate(['/contact-us']);
      this.urlImage='linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("assets/images/contact-us/contact-us.jpg")';
    }
    
    
  }
  
}
