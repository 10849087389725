import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {

  constructor(private router:Router) { }
  isMenuOpen = false;
  ngOnInit() {
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }

}
