// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA9gpeDEEyTVB4Z_uDMWGKuRQTTIO97D4M",
    authDomain: "mascella-8a352.firebaseapp.com",
    projectId: "mascella-8a352",
    storageBucket: "mascella-8a352.appspot.com",
    messagingSenderId: "225930156808",
    appId: "1:225930156808:web:c2aaf26ee9969ee9c9eabd"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
