import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from '@angular/material';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private router:Router,private httpClient: HttpClient,private snackBar: MatSnackBar) { }
  isMenuOpen = false;
  fullName='';
  email='';
  description='';
  ngOnInit() {
    /*const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    let url='https://formspree.io/f/mqkolepr';
    let data = `name=Imaad&email=imaad@platformcommons.com&message=test`;
    let errorMessage: string = "";
    this.httpClient.post<any>(url, data, httpOptions).subscribe({
      next: data => {
          console.log("email sent" + JSON.stringify(data));
      },
      error: error => {
          errorMessage = error.message;
          console.log('error!', errorMessage);
      }
  })*/
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  submitButtonClicked(){
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if(this.fullName.length==0 || this.fullName.replace(/\s/g, "").length==0)
    {
      this.snackBar.open('Please enter your name', 'OK', { duration: 0 });
    }else if(this.email.length==0 || this.email.replace(/\s/g, "").length==0)
    {
      this.snackBar.open('Please enter your email', 'OK', { duration: 0 });
    }else if(this.description.length==0 || this.description.replace(/\s/g, "").length==0)
    {
      this.snackBar.open('Description cannot be empty', 'OK', { duration: 0 });
    }else if(!regexp.test(this.email))
    {
      this.snackBar.open('Please enter a valid email', 'OK', { duration: 0 });
    }else{
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        })
      };
      let url='https://formspree.io/f/mqkolepr';
      let data = 'name='+this.fullName+'&email='+this.email+'&message='+this.description;
      let errorMessage: string = "";
      this.httpClient.post<any>(url, data, httpOptions).subscribe({
        next: data => {
            console.log("email sent" + JSON.stringify(data));
            this.snackBar.open('Message sent successfuly.', 'OK', { duration: 0 });
        },
        error: error => {
            errorMessage = error.message;
            console.log('error!', errorMessage);
        }
    })
    }
    
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }
}
