import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDividerModule} from '@angular/material/divider';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OurTeamComponent } from './our-team/our-team.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import { OutsideHeaderComponent } from './outside-header/outside-header.component';
import { MessageCeoComponent } from './message-ceo/message-ceo.component';
import { OurMessageComponent } from './our-message/our-message.component';
import { FooterComponent } from './footer/footer.component';
import { MatSelectModule, MatSnackBar, MatSnackBarModule } from '@angular/material';
import { HomeFabricsComponent } from './home-fabrics/home-fabrics.component';
import { SustainableFabricsComponent } from './sustainable-fabrics/sustainable-fabrics.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ImgMagnifier } from "ng-img-magnifier";
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { LoginComponent } from './login/login.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { HomeFabricsDivisionComponent } from './home-fabrics-division/home-fabrics-division.component';
import { UpholstryComponent } from './upholstry/upholstry.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ProductsComponent,
    AboutUsComponent,
    ContactUsComponent,
    OurTeamComponent,
    OutsideHeaderComponent,
    MessageCeoComponent,
    OurMessageComponent,
    FooterComponent,
    HomeFabricsComponent,
    SustainableFabricsComponent,
    LoginComponent,
    TrackOrderComponent,
    HomeFabricsDivisionComponent,
    UpholstryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDividerModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatPaginatorModule,
    HttpClientModule,
    FormsModule,
    MatSnackBarModule,
    ImgMagnifier,
    NgxImageZoomModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
