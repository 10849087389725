import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-fabrics',
  templateUrl: './home-fabrics.component.html',
  styleUrls: ['./home-fabrics.component.css']
})
export class HomeFabricsComponent implements OnInit {
  
  constructor(private router:Router) { }
  productCategories=[{
    'title':'Bloom',
    'description':'From the lush texture of velvet to the aged elegance of leather, your fabrics should be able to speak for you',
    'images':[
      'assets/images/home-fabrics/Bloom/01 Bloom.jpg',
      'assets/images/home-fabrics/Bloom/02 Bloom.jpg',
      'assets/images/home-fabrics/Bloom/03 Bloom.jpg',
      'assets/images/home-fabrics/Bloom/04 Bloom.jpg'
    ]
  },
  {
    'title':'Bryant',
    'description':'The affluence of a finely chosen fabric is a testament to the attention to detail of the artist who choses them',
    'images':[
      'assets/images/home-fabrics/Bryant/01 Bryant.jpg',
      'assets/images/home-fabrics/Bryant/02 Bryant.jpg',
      'assets/images/home-fabrics/Bryant/03 Bryant.jpg',
      'assets/images/home-fabrics/Bryant/04 Bryant.jpg'
    ]
  },
  
  {
    'title':'Chevron',
    'description':'Textiles have the power to elevate mere objects to works of art that inspire and delight the senses',
    'images':[
      'assets/images/home-fabrics/Chevron/01 Chevron.jpg',
      'assets/images/home-fabrics/Chevron/02 Chevron.jpg',
      'assets/images/home-fabrics/Chevron/03 Chevron.jpg',
      'assets/images/home-fabrics/Chevron/04 Chevron.jpg'
    ]
  },
  ];
  allProductCategories=[
  
  {
    'title':'Bloom',
    'description':'From the lush texture of velvet to the aged elegance of leather, your fabrics should be able to speak for you',
    'images':[
      'assets/images/home-fabrics/Bloom/01 Bloom.jpg',
      'assets/images/home-fabrics/Bloom/02 Bloom.jpg',
      'assets/images/home-fabrics/Bloom/03 Bloom.jpg',
      'assets/images/home-fabrics/Bloom/04 Bloom.jpg'
    ]
  },
  {
    'title':'Bryant',
    'description':'The affluence of a finely chosen fabric is a testament to the attention to detail of the artist who choses them',
    'images':[
      'assets/images/home-fabrics/Bryant/01 Bryant.jpg',
      'assets/images/home-fabrics/Bryant/02 Bryant.jpg',
      'assets/images/home-fabrics/Bryant/03 Bryant.jpg',
      'assets/images/home-fabrics/Bryant/04 Bryant.jpg'
    ]
  },
  
  {
    'title':'Chevron',
    'description':'Textiles have the power to elevate mere objects to works of art that inspire and delight the senses',
    'images':[
      'assets/images/home-fabrics/Chevron/01 Chevron.jpg',
      'assets/images/home-fabrics/Chevron/02 Chevron.jpg',
      'assets/images/home-fabrics/Chevron/03 Chevron.jpg',
      'assets/images/home-fabrics/Chevron/04 Chevron.jpg'
    ]
  },
  
  {
    'title':'Edison',
    'description':'Delicately intricate, ethereal and airy, elegant and timeless.',
    'images':[
      'assets/images/home-fabrics/Edison/01 Edison.jpg',
      'assets/images/home-fabrics/Edison/02 Edison.jpg',
      'assets/images/home-fabrics/Edison/03 Edison.jpg',
      'assets/images/home-fabrics/Edison/04 Edison.jpg'
    ]
  },
  
  
  {
    'title':'Finch',
    'description':'You can practically feel the quality of the fabric, with its sturdy yet delicate weave.',
    'images':[
      'assets/images/home-fabrics/Finch/01 Finch.jpg',
      'assets/images/home-fabrics/Finch/02 Finch.jpg',
      'assets/images/home-fabrics/Finch/03 Finch.jpg',
      'assets/images/home-fabrics/Finch/04 Finch.jpg'
    ]
  },
  {
    'title':'Firenze',
    'description':'This fabric exudes sophistication, with its intricate details and rich textures.',
    'images':[
      'assets/images/home-fabrics/Firenze/01 Firenze.jpg',
      'assets/images/home-fabrics/Firenze/02 Firenze.jpg',
      'assets/images/home-fabrics/Firenze/03 Firenze.jpg',
      'assets/images/home-fabrics/Firenze/04 Firenze.jpg'
    ]
  },
  {
    'title':'Franklin',
    'description':'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci',
    'images':[
      'assets/images/home-fabrics/Franklin/01 Franklin.jpg',
      'assets/images/home-fabrics/Franklin/02 Franklin.jpg',
      'assets/images/home-fabrics/Franklin/03 Franklin.jpg',
      'assets/images/home-fabrics/Franklin/04 Franklin.jpg'
    ]
  },
  
  {
    'title':'Irvine',
    'description':'The allure of these fabrics is unmistakable, inspiring confidence and grace in anyone who wears them.',
    'images':[
      'assets/images/home-fabrics/Irvine/01 Irvine.jpg',
      'assets/images/home-fabrics/Irvine/02 Irvine.jpg',
      'assets/images/home-fabrics/Irvine/03 Irvine.jpg',
      'assets/images/home-fabrics/Irvine/04 Irvine.jpg'
    ]
  },
  
  {
    'title':'Sapphire',
    'description':'With their timeless appeal and understated elegance, these materials are the perfect choice for anyone who wants to make a statement without saying a word.',
    'images':[
      'assets/images/home-fabrics/Sapphire/01 Sapphire.jpg',
      'assets/images/home-fabrics/Sapphire/02 Sapphire.jpg',
      'assets/images/home-fabrics/Sapphire/03 Sapphire.jpg',
      'assets/images/home-fabrics/Sapphire/04 Sapphire.jpg'
    ]
  },
  {
    'title':'Winston',
    'description':'Whether flowing like water or structured like a work of art, these materials are the building blocks of timeless fashion and grace.',
    'images':[
      'assets/images/home-fabrics/Winston/01 Winston.jpg',
      'assets/images/home-fabrics/Winston/02 Winston.jpg',
      'assets/images/home-fabrics/Winston/03 Winston.jpg',
      'assets/images/home-fabrics/Winston/04 Winston.jpg'
    ]
  }
  ] 
  isMenuOpen = false;
  ngOnInit() {
    
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  aboutUsClicked(){
    this.router.navigate(['/about-us']);
  }
  contactUsClicked(){
    this.router.navigate(['/contact-us']);
  }
  productsClicked(){
    this.router.navigate(['/products']);
  }
  ourTeamClicked(){
    this.router.navigate(['/our-team']);
  }
  homeClicked(){
    this.router.navigate(['/home']);
  }
  paginationPage(event)
  {
    
    let elementsToShow=(event['pageIndex']+1)*3;
    elementsToShow-=1;
    this.productCategories=[];
    this.productCategories.push(this.allProductCategories[elementsToShow-2]);
    this.productCategories.push(this.allProductCategories[elementsToShow-1]);
    this.productCategories.push(this.allProductCategories[elementsToShow]);
  }
  loginClicked(){
    this.router.navigate(['/login']);
  }

}
