import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css']
})
export class TrackOrderComponent implements OnInit {

  constructor(private route: ActivatedRoute,private httpClient:HttpClient,private router:Router) { }
  status;
  statusSplit=[[]];
  orderNo;
  statusForPC;
  ngOnInit() {
    console.warn(this.route.snapshot.paramMap.get('email'));
    this.httpClient.get('assets/json/users.json').subscribe((data:any) => {
      console.warn(data);
      for(let i=0;i<data.length;i++)
      {
        if(data[i]['Email']==this.route.snapshot.paramMap.get('email'))
        {
          this.status=data[i]['status'];
          this.orderNo=data[i]['orderNumber'];
          break;
          
        }
      }
      console.warn(this.status);
      this.statusForPC=this.status;
      this.statusForPC[0]['orderNo']=this.orderNo;
      for(let i=1;i<this.statusForPC.length;i++)
      {
        this.statusForPC[i]['orderNo']='';
      }
      for(let i=0;i<this.status.length;i++)
      {
        if(i<4)
        {
          this.statusSplit[0].push(this.status[i]);
        }else{
          if(this.statusSplit.length==1)
          {
            this.statusSplit.push([]);
            this.statusSplit[1].push(this.status[i]);
          }else{
            this.statusSplit[1].push(this.status[i]);
          }
        }
      }
      console.warn(this.statusSplit);
      

    })
  }
  goBackClicked(){
    this.router.navigate(['/login'])
  }

}
